const en_US = {
    info: {
        title: "Rina Button",
        info: "Information",
        null: "Empty",
        audioStaff: "Audio Clip: A1exMinatoooo",
        toGithub: "Please participate in translation, add audio or make suggestions on Github:",
        notOfficial: "This site is a fan work and is not associated with the LepusLive official."
    },
    action: {
        toggleNavbar: "Toggle navigation bar",
        close: "close",
        copy: "copy",
        control: "Player control",
        stopvoice: "Stop current voice"
    },
    lang: {
        'zh-CN': "简体中文",
        'en-US': "English",
        'ja-JP': "日本語"
    }
}
export default en_US;